<template>
  <div class="subject-details">
    <v-row>
      <v-col cols="12" md="6">
        <h2>{{ $t("Subject Details") }}</h2>
      </v-col>
      <v-col cols="12" md="6">
        <div class="btn-row">
          <v-btn class="blue-btn">
            Print
          </v-btn>
          <!-- <v-btn class="green-btn">
            Unconfirmed
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-select
          solo
          dense
          :items="academicPeriod"
          label="Academic Period"
        ></v-select>
      </v-col>
      <v-col
        ><v-select solo dense :items="quarter" label="Quarter"></v-select
      ></v-col>
      <v-col>
        <v-select solo dense :items="grades" label="Grades"></v-select>
      </v-col>
      <v-col>
        <v-select solo dense :items="classes" label="Classes"></v-select>
      </v-col>
      <v-col>
        <v-select solo dense :items="subjects" label="Subjects"></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn small class="icon-btn" icon @click="addColumn()">
          <v-icon color="#ffffff">mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ headers[0] }}
                </th>
                <th>
                  {{ headers[1] }}
                </th>
                <th>
                  {{ headers[2] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="student in students" :key="student">
                <td class="text-left">{{ student.name }}</td>
                <td
                  class="text-left"
                  :class="student.subject > 50 ? 'pass-grade' : 'failed-grade'"
                >
                  {{
                    student.details.Gebra +
                      student.details.Statistics +
                      student.details.Statistics
                  }}
                  / {{ totalMark }}
                </td>

                <td class="text-left">
                  <span class="mx-3">
                    Gebra {{ student.details.Gebra }} / 40</span
                  >
                  <span class="mx-3"
                    >Statistics {{ student.details.Statistics }} / 40</span
                  >
                  <span class="mx-3"
                    >Trigonometry {{ student.details.Statistics }} / 40
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "subjectDetails",
  data() {
    return {
      headers: ["Student Name", "Subject", "Details"],
      totalMark: 100,
      students: [
        {
          name: "Mohamed",
          subject: 60,
          details: { Gebra: 30, Statistics: 20, Trigonometry: 40 }
        },
        {
          name: "Khaled",
          subject: 50,
          details: { Gebra: 20, Statistics: 15, Trigonometry: 35 }
        },
        {
          name: "Ahmed",
          subject: 100,
          details: { Gebra: 22, Statistics: 32, Trigonometry: 40 }
        },
        {
          name: "Mahmoud",
          subject: 100,
          details: { Gebra: 20, Statistics: 22, Trigonometry: 23 }
        },
        {
          name: "Salah",
          subject: 20,
          details: { Gebra: 10, Statistics: 8, Trigonometry: 2 }
        }
      ],
      academicPeriod: ["2016", "2017", "2018", "2019", "2020"],
      quarter: ["First", "Second", "Third", "Fourth"],
      grades: ["one", "two", "three", "four"],
      classes: ["one", "two", "three", "four"],
      subjects: ["Math", "English", "Science"]
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
.subject-details {
  padding: 2rem 0rem;
}
td,
h2 {
  color: rgba(0, 0, 0, 0.6) !important;
}
h2 {
  font-weight: bold;
}
td {
  font-size: 12px !important;
}
tr:nth-of-type(even) {
  background-color: #ecf1ff !important;
}

.text-left {
  text-align: left !important;
}
.pass-grade {
  color: #33eadf !important;
}
.failed-grade {
  color: #ecc034 !important;
}
.btn-row {
  display: flex;
  justify-content: flex-end;
  padding: 0pc 2rem;
  .green-btn {
    background-color: #4caf50 !important;
    margin: 0rem 1rem;
  }
  .blue-btn {
    background-color: #1976d2 !important;
    margin: 0rem 1rem;
  }
}
</style>
